import './App.css';
import React, {useEffect, useState } from 'react';
import DebounceSelect from './components/DebounceSelect/DebounceSelect';
import CFCard from './components/CFCard/CFCard';
import { fetchCF, fetchCityList } from './services/api';
import { ConfigProvider, theme, Form, Select } from 'antd';


const App = () => {
  const [cityName, setCityName] = useState(() => {
    // Retrieve data from localStorage initially (if it exists)
    const cityName = localStorage.getItem('cityName');
    return cityName || null; // Default to 'null' if no value is found
  });

  const [latlong, setLatLong] =  useState(() => {
    // Retrieve data from localStorage initially (if it exists)
    const latlong = localStorage.getItem('latlong');
    return latlong || null; // Default to 'null' if no value is found
  });

  const [std, setStd] = useState(() => {
    // Retrieve data from localStorage initially (if it exists)
    const storedStd = localStorage.getItem('selectedStd');
    return storedStd || 'sae'; // Default to 'sae' if no value is found
  });
  const [cfData, setCFData] = useState(null);

  useEffect(() => {
    if (latlong && std) {
      fetchCF(latlong.split(',')[0], latlong.split(',')[1], std)
        .then((response) => {
          // Handle the response
          //console.log('response:', response);
          setCFData(response);
        })
        .catch((error) => {
          // Handle the error
          console.error('Error fetching CF:', error);
        });
    }
    if (std) {
      localStorage.setItem('selectedStd', std);
    }

    if (latlong) {
      localStorage.setItem('latlong', latlong);
    }

  }, [latlong, std]);

  useEffect(() => {
    if (cityName) {
      localStorage.setItem('cityName', cityName);
    }
  }, [cityName]);

  
  const handleStdChange = (value) => {
    //console.log(`selected ${value}`);
    setStd(value);
  }

  const handleSelectChange = (value, selectedOption) => {
    //console.log('selected option: ', selectedOption);
    setCityName(value);
    setLatLong(selectedOption.latlong);
  }
  

  return (
      <ConfigProvider
        theme={{
          // 1. Use dark algorithm
          algorithm: theme.lightAlgorithm,

          // 2. Combine dark algorithm and compact algorithm
          // algorithm: [theme.darkAlgorithm, theme.compactAlgorithm],
        }}
      >

      <div className="calculator-container">
      <h1>Calculador de Factor de Corrección Climático Accudyno</h1>
      <Form
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        style={{ maxWidth: 600 }}
      >
        <Form.Item label="Localidad">
          <DebounceSelect
            id="localidad"
            value={cityName}
            placeholder="Seleccione la localidad"
            fetchOptions={fetchCityList}
            onChange={(newValue) => {
              setCityName(newValue);
            }}
            onSelect ={handleSelectChange}
          />
        </Form.Item>
        <Form.Item label="Norma">
          <Select defaultValue={std} onChange={handleStdChange}>
            <option value="sae">SAE J1349/2004</option>
            <option value="iso">ISO 1585</option>
            <option value="ewg">EWG 80/1269</option>
            <option value="din">DIN 70020</option>
            <option value="j607">SAE J607</option>
            <option value="jis">JIS D 1001</option>
          </Select>
        </Form.Item>
      </Form>
      {cfData && (
        <CFCard 
          city={cityName}
          temperature={cfData.temperature}
          humidity={cfData.humidity}
          pressure={cfData.pressure}
          correctionFactor={cfData.cf}
          standard={cfData.std}
          className="cf-card"
        />
      )}
    </div>
    </ConfigProvider>
  );
};



export default App;