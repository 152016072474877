
async function fetchCityList(cityQuery) {
    console.log('fetching city query: ', cityQuery);
    if(!cityQuery) {
      return [];
    }
  
    try {
      const response = await fetch(`./api/cities?q=${cityQuery}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const results = await response.json();
      console.log('response OK : ', results);
      return results.map((city) => ({
        label: city.label,
        value: city.label,
        latlong: `${city.lat},${city.lon}`,
      }));
    } catch (error) {
      console.error('Error fetching city list:', error);
      return [];
    }
  }

  async function fetchCF(lat, lon, std) {
    console.log('fetching CF: ', lat, lon, std);
    if(!lat || !lon || !std) {
      return [];
    }
  
    try {
      const response = await fetch(`./api/cf?lat=${lat}&lon=${lon}&std=${std}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const results = await response.json();
      console.log('response OK : ', results);
      return results;
    } catch (error) {
      console.error('Error fetching CF:', error);
      return [];
    }
  }

  
  export { fetchCityList, fetchCF };