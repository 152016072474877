import React from 'react';
import { Statistic } from 'antd';

const CFCard = ({ city, temperature, humidity, pressure, correctionFactor, standard }) => {

    return (
        <div className="cf-card" >
            <h2>{city}</h2>
            <div className="weather-stats">
                <Statistic title="Temperatura" value={temperature} precision={1} suffix={"°C"} decimalSeparator={","} groupSeparator={"."}/>
                <Statistic title="Humedad" value={humidity} precision={0} suffix={"%"} decimalSeparator={","} groupSeparator={"."}/>
                <Statistic title="Presión Atmosférica" value={pressure} precision={1} suffix={"hPa"} decimalSeparator={","} groupSeparator={"."}/>
            </div>
            <div className="correction-factor">
                <Statistic title="Factor de Corrección" value={correctionFactor} precision={4} decimalSeparator={","} groupSeparator={"."}/>
            </div>
            <div className="footnote">
                <p>{standard}</p>
            </div>
            <a href="https://www.accudyno.com">www.accudyno.com</a>
        </div>
    );
};

export default CFCard;
